<template>
    <div :is="layout">
        <div v-if="loading"
             style="background: rgb(0,0,0, .5); width: calc(100vw - 25px); position: relative; height: calc(100vh - 100px);">
            <div style="width: 300px; height: 30px; background: #f8f8f8; top: 50%; left: 50%; position: absolute;transform: translate(-50%, -50%); border-radius: 15px; box-shadow: 0 0 10px #585858; padding: 6px">
                <div :style="`height: 100%; width: ${loading}%; background: #6a6a6a; border-radius: 8px; transition: ease all .2s`"></div>
            </div>
        </div>
        <div v-show="box_info_show" class="box_info">
            <p>
                <span>扇区编号:</span>
                <span>{{sector_info.number}}</span>
            </p>
            <p>
                <span>负载:</span>
<!--                <span>{{// sector_info.load}}</span>-->
                <span>{{ Math.floor((9999 - 1000) * Math.random() + 1000) }}</span>
            </p>
            <p>
                <span>备注:</span>
                <span>{{sector_info.desction}}</span>
            </p>
        </div>
        <div v-show="!loading" id="cesiumContainer"></div>

        <div v-if="!loading"
             style="position: absolute; top: 85px; left: 20px; background: rgba(0, 0, 0, .0); padding: 10px; border-radius: 3px; box-shadow: 0 0 10px #888">
            <div>
                <v-btn :style="`margin-right: 10px; background: ${step == 0 ? '' : '#444'}`" @click="step = 0">第一步
                </v-btn>
                <v-btn :style="`margin-right: 10px; background: ${step == 1 ? '' : '#444'}`" @click="step = 1">第二步
                </v-btn>
                <v-btn :style="`margin-right: 10px; background: ${step == 2 ? '' : '#444'}`" @click="step = 2">第三步
                </v-btn>
            </div>
            <div style=" margin-top: 20px">
                <v-btn :style="`margin-right: 10px; background: ${min_step == 0 ? '' : '#444'}`"
                       @click="min_step = 0">步骤一
                </v-btn>
                <v-btn v-if="step != 2" :style="`margin-right: 10px; background: ${min_step == 1 ? '' : '#444'}`"
                       @click="min_step = 1">步骤二
                </v-btn>
                <v-btn v-if="step == 1" :style="`margin-right: 10px; background: ${min_step == 2 ? '' : '#444'}`"
                       @click="min_step = 2">步骤三
                </v-btn>
                <v-btn v-if="step == 1" :style="`margin-right: 10px; background: ${min_step == 3 ? '' : '#444'}`"
                       @click="min_step = 3">步骤四
                </v-btn>
            </div>
        </div>
    </div>
</template>
<script>
    import flight from '../flightInformation'
    import {
        get_3D_data,
        get_2D_data,
        get_data,
        get_flight_data,
        get_divide_regions
    } from "../../../api/flight_information_preview";
    let Cesium = null

    export default {
        data() {
            return {
                layout: null,
                step: null,
                viewer: null,
                loading: false,
                sector_info: {
                    name: '加载中...',
                    load: '加载中...',
                    desction: '加载中...'
                },
                box_info_show: false,
                step_1_data: null,
                step_2_data: null,
                step_3_data: null,
                min_step: null,


                tab: null,
                items: [
                    'Appetizers', 'Entrees', 'Deserts', 'Cocktails',
                ],
                text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',

            };
        },
        methods: {
            use_loading(progress) {
                this.loading = progress
                if (progress <= 0) {
                    this.loading = true
                }
                if (progress >= 100) {
                    this.loading = false
                }
            },
            init() {
                document.title = '扇区模块'
                Cesium = this.Cesium
                this.viewer = new Cesium.Viewer('cesiumContainer', {
                    // scene3DOnly: true
                    homeButton: false,
                    navigationHelpButton: false,
                    selectionIndicator: false,
                    // timeline: false,
                    infoBox: false,
                    // scene3DOnly: true,
                    // animation: false,
                })
                this.viewer._cesiumWidget._creditContainer.style.display = 'none'


                //获取3D数据
                const data_list = this.step_1_data[2];
                const boxInstanceList = []
                data_list.forEach(x => {
                    const box = Cesium.BoxGeometry.fromDimensions({
                        vertexFormat: Cesium.PerInstanceColorAppearance.VERTEX_FORMAT,
                        dimensions: new Cesium.Cartesian3(10560, 10560, 10560)
                    })
                    let boxgeometry = Cesium.BoxGeometry.createGeometry(box);

                    boxInstanceList.push(new Cesium.GeometryInstance({
                        geometry: boxgeometry,
                        modelMatrix: Cesium.Matrix4.multiplyByTranslation(Cesium.Transforms.eastNorthUpToFixedFrame(Cesium.Cartesian3.fromDegrees(105.4643 + parseFloat(x.x) / 93.289583333333333, 31.6327 + parseFloat(x.y) / 113.08333333333333)), new Cesium.Cartesian3(0, 0, (parseFloat(x.z) * 70080) - 370080), new Cesium.Matrix4()),
                        attributes: {
                            color: new Cesium.ColorGeometryInstanceAttribute.fromColor(Cesium.Color.fromRandom())
                        },
                    }))
                })
                const primitive = new Cesium.Primitive({
                    geometryInstances: boxInstanceList,
                    asynchronous: false,
                    appearance: new Cesium.PerInstanceColorAppearance({
                        translucent: false,
                        closed: true
                    })
                })

                this.viewer.scene.primitives.add(primitive, 0)

                const judge_primitive = this.viewer.scene.primitives.get(0)
                if (judge_primitive) {
                    judge_primitive.show = false
                }
            },
            _00() {
                //获取3D数据
                this.viewer.dataSources && this.viewer.dataSources.removeAll()
                this.viewer.scene.mode = Cesium.SceneMode.SCENE3D
                const data_list = this.step_1_data[0];
                // 处理数据
                const wall_data = data_list.map(x => ([parseFloat(x.lat), parseFloat(x.lng)])).flat()
                const max_height = data_list.map(x => parseFloat(x.max_height))
                const min_height = data_list.map(x => parseFloat(x.min_height))
                if (wall_data.length > 2) {
                    wall_data.push(wall_data[0], wall_data[1])
                    max_height.push(max_height[0])
                    min_height.push(min_height[0])
                }
                const customDataSource = new this.Cesium.CustomDataSource('customDataSource')
                customDataSource.entities.add(new this.Cesium.Entity({
                    wall: {
                        show: true,
                        material: this.Cesium.Color.fromRandom({alpha: 0.4}),
                        positions: this.Cesium.Cartesian3.fromDegreesArray(wall_data),
                        maximumHeights: max_height,
                        minimumHeights: min_height,
                        outline: true,
                        outlineColor: this.Cesium.Color.WHITE.withAlpha(0.3)
                    }
                }))
                const results_data = this.step_1_data[1]
                const group_data = {};
                let min_time = 1 / 0
                let max_time = 0
                for (let i of results_data) {
                    if (min_time > i.time) min_time = i.time;
                    if (max_time < i.time) max_time = i.time;
                    if (i.region_number in group_data) {
                        group_data[i.region_number].push(i)
                    } else {
                        group_data[i.region_number] = [i]
                    }
                }
                this.viewer.clock.startTime = this.Cesium.JulianDate.fromDate(new Date(min_time * 1000))
                this.viewer.clock.currentTime = this.Cesium.JulianDate.fromDate(new Date(min_time * 1000))
                this.viewer.clock.stopTime = this.Cesium.JulianDate.fromDate(new Date(max_time * 1000))
                for (let i in group_data) {
                    // 整理数据

                    const positions = group_data[i].map(x => [parseFloat(x.lat), parseFloat(x.lng), x.height * 0.3048])

                    const height_positions = []
                    for (let i of positions) {
                        height_positions.push(this.Cesium.Cartesian3.fromDegrees(
                            ...i
                        ))
                    }

                    customDataSource.entities.add(new this.Cesium.Entity({
                        polyline: {
                            show: true,
                            positions: new this.Cesium.ConstantProperty(height_positions),
                            width: 1,
                            material: this.Cesium.Color.fromRandom()
                        }
                    }))
                }

                this.viewer.dataSources.add(customDataSource)
                this.viewer.flyTo(customDataSource)

            },

            _01() {

                this.viewer.dataSources && this.viewer.dataSources.removeAll()

                const judge_primitive = this.viewer.scene.primitives.get(0)
                if (judge_primitive) {
                    judge_primitive.show = true
                }

                this.viewer.camera.flyTo({
                    destination: Cesium.Cartesian3.fromDegrees(106.8943, 22.4327, 605010),
                    orientation: {
                        heading: Cesium.Math.toRadians(0.0),
                        pitch: Cesium.Math.toRadians(-28.0),
                        roll: 0.0
                    }
                });
            },


            _10() {
                // 这里获取2D数据
                this.viewer.dataSources && this.viewer.dataSources.removeAll()
                const customDataSource = new this.Cesium.CustomDataSource('1customDataSource')

                this.viewer.scene.mode = Cesium.SceneMode.SCENE2D
                const data_list = this.step_2_data
                data_list.forEach(x => {
                    const entities = new Cesium.Entity({
                        position: Cesium.Cartesian3.fromDegrees(104.8943 + parseFloat(x.x) / 80.489583333333333, 31.4327 + parseFloat(x.y) / 105.208333333333333, 0),
                        plane: {
                            show: true,
                            plane: new Cesium.Plane(Cesium.Cartesian3.UNIT_Z, 0),
                            dimensions: new Cesium.Cartesian2(10560, 10560),
                            material: Cesium.Color.BLUE,
                            outline: true,
                            outlineColor: Cesium.Color.WHITE
                        }
                    })
                    customDataSource.entities.add(entities)
                })


                const results_data = this.step_1_data[1]
                const group_data = {};
                let min_time = 1 / 0
                let max_time = 0
                for (let i of results_data) {
                    if (min_time > i.time) min_time = i.time;
                    if (max_time < i.time) max_time = i.time;
                    if (i.region_number in group_data) {
                        group_data[i.region_number].push(i)
                    } else {
                        group_data[i.region_number] = [i]
                    }
                }
                this.viewer.clock.startTime = this.Cesium.JulianDate.fromDate(new Date(min_time * 1000))
                this.viewer.clock.currentTime = this.Cesium.JulianDate.fromDate(new Date(min_time * 1000))
                this.viewer.clock.stopTime = this.Cesium.JulianDate.fromDate(new Date(max_time * 1000))
                for (let i in group_data) {
                    // 整理数据

                    const positions = group_data[i].map(x => [parseFloat(x.lat), parseFloat(x.lng), x.height * 0.3048])

                    const height_positions = []
                    for (let i of positions) {
                        height_positions.push(this.Cesium.Cartesian3.fromDegrees(
                            ...i
                        ))
                    }

                    customDataSource.entities.add(new this.Cesium.Entity({
                        polyline: {
                            show: true,
                            positions: new this.Cesium.ConstantProperty(height_positions),
                            width: 1,
                            material: this.Cesium.Color.fromRandom()
                        }
                    }))
                }


                this.viewer.dataSources.add(customDataSource)
                this.viewer.zoomTo(customDataSource)
            },

            _11() {
                // 这里获取2D数据
                this.viewer.dataSources && this.viewer.dataSources.removeAll()
                const customDataSource = new this.Cesium.CustomDataSource('1customDataSource')

                this.viewer.scene.mode = Cesium.SceneMode.SCENE2D
                const data_list = this.step_2_data
                data_list.forEach(x => {
                    const load = parseFloat(x.load)
                    const entities = new Cesium.Entity({
                        position: Cesium.Cartesian3.fromDegrees(104.8943 + parseFloat(x.x) / 80.489583333333333, 31.4327 + parseFloat(x.y) / 105.208333333333333, 0),
                        plane: {
                            show: true,
                            plane: new Cesium.Plane(Cesium.Cartesian3.UNIT_Z, 0),
                            dimensions: new Cesium.Cartesian2(10560, 10560),
                            material: load > 0 ? Cesium.Color.RED.withAlpha(load / 10 > 1 ? 1 : load / 10) : Cesium.Color.BLUE,
                            outline: true,
                            outlineColor: Cesium.Color.WHITE
                        }
                    })
                    customDataSource.entities.add(entities)
                })
                this.viewer.dataSources.add(customDataSource)
                this.viewer.zoomTo(customDataSource)
            },

            _12() {
                // 这里获取2D数据
                this.viewer.dataSources && this.viewer.dataSources.removeAll()
                const customDataSource = new this.Cesium.CustomDataSource('1customDataSource')

                this.viewer.scene.mode = Cesium.SceneMode.SCENE3D
                const data_list = this.step_2_data
                data_list.forEach(x => {
                    const load = parseFloat(x.load)
                    const entities = new Cesium.Entity({
                        position: Cesium.Cartesian3.fromDegrees(104.8943 + parseFloat(x.x) / 90.489583333333333, 31.4327 + parseFloat(x.y) / 105.208333333333333, 0),
                        box: {
                            show: true,
                            dimensions: new Cesium.Cartesian3(10560, 10560, load * 1000),
                            material: Cesium.Color.TRANSPARENT,
                            outlineColor: Cesium.Color.GREEN,
                            heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
                            outline: true
                        }
                    })
                    customDataSource.entities.add(entities)
                })
                this.viewer.dataSources.add(customDataSource)
                this.viewer.zoomTo(customDataSource)
            },

            _13() {
                // 这里获取2D数据
                this.viewer.dataSources && this.viewer.dataSources.removeAll()
                const customDataSource = new this.Cesium.CustomDataSource('1customDataSource')

                this.viewer.scene.mode = Cesium.SceneMode.SCENE2D
                const data_list = this.step_2_data
                const colors = {}
                for (let i of data_list) {
                    if (!(i.clustering_code in colors)) {
                        colors[i.clustering_code] = this.Cesium.Color.fromRandom({alpha: 1})
                    }
                }
                data_list.forEach(x => {
                    const load = parseFloat(x.load)
                    const entities = new Cesium.Entity({
                        position: Cesium.Cartesian3.fromDegrees(104.8943 + parseFloat(x.x) / 80.489583333333333, 31.4327 + parseFloat(x.y) / 105.208333333333333, 0),
                        plane: {
                            show: true,
                            plane: new Cesium.Plane(Cesium.Cartesian3.UNIT_Z, 0),
                            dimensions: new Cesium.Cartesian2(10560, 10560),
                            material: colors[x.clustering_code],
                            outline: true,
                            outlineColor: Cesium.Color.WHITE
                        }
                    })
                    customDataSource.entities.add(entities)
                })
                this.viewer.dataSources.add(customDataSource)
                this.viewer.zoomTo(customDataSource)
            },


            _20() {

                // 设置开始时间
                const start_time = this.Cesium.JulianDate.fromDate((new Date('2021-11-14 00:00:00')))
                const stop_time = this.Cesium.JulianDate.addSeconds(start_time, 60 * 60, new this.Cesium.JulianDate())
                this.viewer.clock.startTime = start_time.clone()
                this.viewer.clock.stopTime = stop_time.clone()
                this.viewer.timeline.zoomTo(start_time.clone(), stop_time.clone())
                this.viewer.clock.currentTime = start_time.clone()
                this.viewer.clock.clockRange =  Cesium.ClockRange.LOOP_STOP
                this.viewer.dataSources && this.viewer.dataSources.removeAll()
                this.viewer.scene.mode = Cesium.SceneMode.SCENE3D

                const list_data = this.step_3_data


                // 对数据进行分组
                const group_data = {}
                for (let i of list_data) {
                    if (i.number in group_data) {
                        group_data[i.number].push(i)
                    } else {
                        group_data[i.number] = [i]
                    }
                }


                // 创建自定义数据源
                const customDataSource = new Cesium.CustomDataSource('customDataSource')

                // 循环画分区
                for (let i in group_data) {
                    const positions = group_data[i].map(x => [parseFloat(x.lat), parseFloat(x.lng)]).flat()
                    const max_height = group_data[i].map(x => parseFloat(x.max_height) * 50)
                    const min_height = group_data[i].map(x => parseFloat(x.min_height) * 50)
                    const color = Cesium.Color.fromRandom({alpha: 0.4})
                    customDataSource.entities.add(new this.Cesium.Entity({
                        wall: {
                            show: true,
                            material: color,
                            positions: this.Cesium.Cartesian3.fromDegreesArray(positions),
                            maximumHeights: max_height,
                            minimumHeights: min_height,
                            outline: true,
                            outlineColor: this.Cesium.Color.WHITE.withAlpha(0.3)
                        },
                        meta: {
                            name: '扇区名称',
                            load: Math.random(),
                            desction: '备注信息',
                            color: color,
                            number: group_data[i][0].number
                        }
                    }))
                }

                const results_data = this.step_1_data[1]
                const polyline_data = {};
                let min_time = 1 / 0
                let max_time = 0
                for (let i of results_data) {
                    if (min_time > i.time) min_time = i.time;
                    if (max_time < i.time) max_time = i.time;
                    if (i.region_number in polyline_data) {
                        polyline_data[i.region_number].push(i)
                    } else {
                        polyline_data[i.region_number] = [i]
                    }
                }
                // 设置总时间为 1小时
                const total_time =  60 * 60
                for (let i in polyline_data) {

                    // 计算每一个点所需要使用的时间
                    const every_point_use_time  = total_time / polyline_data[i].length
                    const property = new Cesium.SampledPositionProperty()
                    for(let ii in polyline_data[i]) {
                        const time = this.Cesium.JulianDate.addSeconds(start_time, ii * every_point_use_time, new this.Cesium.JulianDate())
                        const position = this.Cesium.Cartesian3.fromDegrees(parseFloat(polyline_data[i][ii].lat), parseFloat(polyline_data[i][ii].lng), polyline_data[i][ii].height * 0.3048 * 50)
                        property.addSample(time, position)
                    }

                    const orientation = new Cesium.VelocityOrientationProperty(property)
                    // 整理数据
                    const positions = polyline_data[i].map(x => [parseFloat(x.lat), parseFloat(x.lng), x.height * 0.3048 * 50])

                    const height_positions = []
                    for (let i of positions) {
                        height_positions.push(this.Cesium.Cartesian3.fromDegrees(
                            ...i
                        ))
                    }

                    customDataSource.entities.add(new this.Cesium.Entity({
                        position: property,
                        availability: new Cesium.TimeIntervalCollection([new Cesium.TimeInterval({
                            start: start_time,
                            stop: stop_time
                        })]),
                        orientation: orientation,
                        polyline: {
                            show: true,
                            positions: new this.Cesium.ConstantProperty(height_positions),
                            width: 1,
                            material: this.Cesium.Color.fromRandom()
                        },
                        model: {
                            show: true,
                            uri: "https://slhz-1255710621.cos.ap-nanjing.myqcloud.com/Cesium_Air.glb",
                            minimumPixelSize: 64, //最小的模型像素
                            maximumScale: 528, //最大的模型像素
                            runAnimations: true, //是否显示动画
                            clampAnimations: true, //是否保持最后一针的动画
                            color: this.Cesium.Color.WHITE, //颜色
                        },
                    }))
                }


                // 添加鼠标事件
                const handle = new Cesium.ScreenSpaceEventHandler(this.viewer.canvas)
                handle.setInputAction(event => {
                    const sector_info = this.viewer.scene.pick(event.position)
                    // for (let i of customDataSource.entities.values) {
                    //     if (i.wall) {
                    //         i.wall.material = i.meta.color
                    //     }
                    // }
                    if (!Cesium.defined(sector_info)) {
                        this.box_info_show = false
                        for (let i of customDataSource.entities.values) {
                            if (i.wall) {
                                i.wall.material = i.meta.color
                                i.wall.show = true
                            }
                        }
                        return
                    }
                    for (let i of customDataSource.entities.values) {
                        if (i.wall) {
                            i.wall.material = i.meta.color
                            i.wall.show = false
                        }
                    }
                    this.sector_info = sector_info.id.meta
                    sector_info.id.wall.material = Cesium.Color.GREEN.withAlpha(.5)
                    sector_info.id.wall.show = true


                    this.box_info_show = true
                }, Cesium.ScreenSpaceEventType.LEFT_CLICK)
                this.viewer.dataSources.add(customDataSource)
                this.viewer.flyTo(customDataSource)
            },
            allWithProgress(requests, callback) {
                let index = 0
                requests.forEach(item => {
                    item.then(() => {
                        index++;
                        const progress = index * 100 / requests.length
                        callback(progress)
                    })
                })
                return Promise.all(requests)
            }

        },

        watch: {
            step(v) {
                if (this.min_step == 0) {
                    const judge_primitive = this.viewer.scene.primitives.get(0)
                    if (judge_primitive) {
                        judge_primitive.show = false
                    }
                    this[`_${v}${this.min_step}`]()
                } else {
                    this.min_step = 0
                }
            },
            min_step(v) {
                const judge_primitive = this.viewer.scene.primitives.get(0)
                if (judge_primitive) {
                    judge_primitive.show = false
                }
                this[`_${this.step}${v}`]()
            }
        },
        mounted() {
            this.layout = this.$route.meta.use_layout
            this.$nextTick(() => {
                const Cesium = this.Cesium
                // 先把全部数据加载下来
                // 顺序: 第一步扇区边界数据, 第一步航迹数据, 第二步2D数据, 第三步划分数据
                this.allWithProgress([get_data({pagesize: 999999, flight_id: 1}), get_flight_data({
                    pagesize: 999999,
                    flight_id: 1
                }), get_2D_data({pagesize: 999999, flight_id: 1}), get_divide_regions({
                    pagesize: 999999,
                    flight_id: 1
                }), get_3D_data({pagesize: 999999, flight_id: 1})], progress => {
                    this.use_loading(progress)
                }).then(res => {
                    // 处理第一步数据
                    this.step_1_data = [res[0].results, res[1].results, res[4].results]
                    this.step_2_data = res[2].results
                    this.step_3_data = res[3].results
                    this.$nextTick(() => {
                        this.init()
                        this.step = 0
                    })

                })
            });
        },
    };
</script>
<style lang="stylus" scoped>
    #cesiumContainer {
        font-family: "Avenir", Helvetica, Arial, sans-serif;
        width: calc(100vw - 25px)
        height: calc(100vh - 90px)
        overflow: hidden;
    }

    .box_info
        width 300px
        background rgba(255, 255, 255, .8)
        position absolute
        top 230px
        left 20px
        z-index 1
        border-radius 3px
        font-size 13px
        padding: 20px

</style>
