import { request } from '@/utils/'

// 获取关联数据
// export const get_3D_data = params => request.get('/api/flight_information_attach_data/', { params })
// // 获取关联数据
// export const get_2D_data = params => request.get('/api/flight_information_attach_data_2d/', { params })


// 获取关联数据
export const get_3D_data = params => request.get('/api/flight_information_attach_data/', { params })
// 获取关联数据
export const get_2D_data = params => request.get('/api/flight_information_attach_data_2d/', { params })

// 获取展示的数据
export const get_data = params => request.get('/api/flight_information_attach_data_region/', { params })
// 获取扇区中的航迹数据
export const get_flight_data = params => request.get('/api/flight_information_line_data/', { params })
// 获取划分后的扇区
export const get_divide_regions = params => request.get('/api/flight_divide_region/', { params })
