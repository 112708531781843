export default {
  flightInformation:[
    {
      id: 1,
      name: "上海飞行情报区",
      flight_information:[
        115.5600, 30.0500,
        115.4848, 32.5430,
        115.5430, 34.3100,
        115.2700, 35.1448,
        115.2400, 36.4000,
        117.3000, 37.2912,
        120.0000, 38.1500,
        123.0000, 38.1500,
        124.0000, 38.0000,
        124.0000, 29.0000,
        121.2656, 26.2908,
        120.0000, 25.0000,
        117.3000, 23.0000,
        117.3000, 23.3000,
        117.1100, 23.4200,
        116.4200, 24.2200,
        115.2430, 24.4024,
        115.2500, 25.0530,
        114.5224, 25.4600,
        114.0700, 26.0300,
        113.5700, 26.4200,
        114.3400, 29.0200,
        115.5600, 30.0500
      ],
      control_area:[{
        name: "上海管制区",
        id:2,
        location: [115.5600, 30.0500,
          115.4848, 32.5430,
          115.5430, 34.3100,
          115.2700, 35.1448,
          115.2400, 36.4000,
          117.3000, 37.2912,
          120.0000, 38.1500,
          123.0000, 38.1500,
          124.0000, 38.0000,
          124.0000, 29.0000,
          121.2656, 26.2908,
          120.0000, 25.0000,
          117.3000, 23.0000,
          117.3000, 23.3000,
          117.1100, 23.4200,
          116.4200, 24.2200,
          115.2430, 24.4024,
          115.2500, 25.0530,
          114.5224, 25.4600,
          114.0700, 26.0300,
          113.5700, 26.4200,
          114.3400, 29.0200,
          115.5600, 30.0500],
        sector:[
          {
            location:[
              117.5748, 28.2642,
              118.1512, 29.4412,
              118.4512, 30.5706,
              119.4712, 29.5715,
              120.1431, 29.5307,
              120.1400, 29.3700,
              120.0130, 28.5400,
              119.3700, 27.2500,
              119.3006, 27.2748,
              118.3154, 27.5512,
              118.0800, 28.1700,
              117.5748, 28.2642,
            ],
            height: 60000,
          },
     
          {
            location:[
              121.4654,31.1012,
              121.3424,31.5537,
              121.4712,31.5236,
              122.1618,31.4930,
              124.0000,33.2400,
              124.0000,29.0000,
              122.2148,29.5600,
              121.1311,30.1518,
              121.4654,31.1012,
            ],
            height:60000
          },
          {
            location:[
              119.4712,29.5715,
              118.4512,30.5706,
              118.4252,31.2414,
              120.1848,31.1911,
              120.1944, 31.3521,
              120.5846,32.0354,
              121.3424,31.5537,
              121.4654,31.1012,
              121.1311,30.1518,
              120.5633,30.2247,
              120.1431,29.5307,
              119.4712,29.5715,
            ],
            height:60000
          }
        ],
      }
      ],
    },
    {
      id: 2,
      name: "广州飞行情报区",
      flight_information:[
        105.323, 23.1145,
        105.48, 24.39,
        107.39, 25.42,
        109.19, 26.35,
        109.23, 28.47,
        109.24, 29.31,
        113.0712, 29.23,
        114.34, 29.02,
        113.57, 26.42,
        114.07, 26.03,
        114.5224, 25.46,
        115.25, 25.053,
        115.243, 24.4024,
        116.42, 24.22,
        117.11, 23.42,
        117.3, 23.3,
        117.3, 23.0,
        115.4, 22.243,
        114.3, 22.243,
        113.52, 22.213,
        111.3, 19.3,
        111.3, 20.3,
        108.03, 20.3,
        108.1231, 21.1235,
        105.323, 23.1145,
      ],
      control_area:[
        {
          name: "广州管制区",
          id: 1,
          location:[105.323, 23.1145, 105.48, 24.39, 107.39, 25.42, 109.19, 26.35,
            109.23, 28.47, 109.24, 29.31, 113.0712, 29.23, 114.34, 29.02,
            113.57, 26.42, 114.07, 26.03, 114.5224, 25.46, 115.25, 25.053,
            115.243, 24.4024, 116.42, 24.22, 117.11, 23.42, 117.3, 23.3, 117.3,
            23.0, 115.4, 22.243, 114.3, 22.243, 113.52, 22.213, 111.3, 19.3,
            111.3, 20.3, 108.03, 20.3, 108.1231, 21.1235, 105.323, 23.1145],
          sector:[
            {
              location: [
                114.12, 23.4306, 114.1941, 23.3855, 114.2742, 23.26, 116.1018,
                23.46, 116.58, 24.0, 116.42, 24.22, 115.243, 24.4024, 115.25,
                25.053, 115.0121, 25.3453, 114.4406, 24.1736, 114.12, 23.4306,
              ],
              height: 60000,
            },
            {
              location: [
                113.42, 25.563, 113.3254, 24.1106, 113.3836, 23.5542, 114.12,
                23.4306, 114.4406, 24.1736, 115.0121, 25.3453, 114.5224, 25.46,
                114.07, 26.03, 113.42, 25.563,
              ],
              height: 60000,
            },
          ],
        },
         {
          name: "桂林管制区",
          id: 2,
          location:[
            109.1900, 26.3500,
            111.2024, 25.1636,
            111.1600, 24.0854,
            110.1106, 24.0742,
            109.5024, 24.4006,
            107.3900, 25.4200,
            109.1900, 26.3500],
          sector:[
            {
              location:[
                109.1900, 26.3500,
                111.2024, 25.1636,
                111.1600, 24.0854,
                110.1106, 24.0742,
                109.5024, 24.4006,
                107.3900, 25.4200,
                109.1900, 26.3500],
                height: 95000
            }
          ]
        }
      ],

    },
  ],
}
